import * as BooksController from "../controller/BooksController";
import * as UserController from "../controller/UserController";
export default function App() {

  return (
    <>
      {/* <UserController.SignUpController />
      <UserController.SignInController />
      <UserController.LogoutController /> */}
      {/* <BooksController.AddBooks />
      <BooksController.UpdateBooks />
      <BooksController.DeleteBooks />
      <BooksController.FetchBooks /> */}
    </>
  );
}
