import config from "../resources/config.json";
import {
  getAuth,
  GoogleAuthProvider,
  signInWithRedirect,
  signOut,
} from "firebase/auth";
import App from "../views/App";
import { initializeApp } from "firebase/app";
import GlobalContext from "../context/GlobalContext";
import { useRef, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";

const app = initializeApp(config.firebase);

export default function AppController() {
  const [text, setText] = useState();
  const [user] = useAuthState(getAuth());

  return (
    <GlobalContext.Provider value={{ setText: setText }}>
      <App />
      <button
        onClick={() => {
          const auth = getAuth();
          const provider = new GoogleAuthProvider();
          signInWithRedirect(auth, provider);
        }}
      >
        Sign In With Google
      </button>
      {user ? (
        <>
          <h1>Logged In</h1>
          <button
            onClick={() => {
              const auth = getAuth();
              signOut(auth);
            }}
          >
            Logout
          </button>
        </>
      ) : (
        <h1>Not Logged In</h1>
      )}
    </GlobalContext.Provider>
  );
}
